import React from "react"
import { graphql } from "gatsby"
import BoatView from "../../views/boat-view"

function Boat(props) {
    const { googleBooteSheet, boatImages } = props.data
  return <BoatView boat={googleBooteSheet} images={boatImages} />
}

export default Boat

export const query = graphql`
  query($id: String!) {
    googleBooteSheet(id: { eq: $id }) {
      nameDe
      nameEn
      slugDe
      typ
      typEn
      kategorie
      anzahlPersonen
      ps
      fuehrerschein
      anzahlBoote
      rwId
      laenge
      breite
      tiefgang
      gewichtKg
      tank
      fuelTypeDe
      fuelTypeEn
      uebernachtung
      uebernachtungAufAnfrage
      beschreibungTeaserDe
      beschreibungRestDe
      beschreibungTeaserEn
      beschreibungRestEn
      kaution
      preisStunde
      preisStandard
      preisGanztags
      preisAfterWork
      preis3Stunden
      preis3Tage
      preis4Tage
      preis5Tage
      preis6Tage
      preis7Tage
      abPreis
      kraftstoffProH
      vollgetanktBeiAbgabe
      sliderImagesQuantity
      skipper
      ausstattungDe
      ausstattungEn
      kuehlbox
      gasgrill
      trockenWc
      wasserdichtesFass
      wasserdichterBeutel
      bookViaPhone
    }
  }
`